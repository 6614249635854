@import 'vars';

@mixin btn-link($bg, $text, $border: $bg) {
  display: block;
  padding: 0.1em 1.3em 0;
  border: 2px solid $border;
  border-radius: 0.8em;
  background: $bg;
  font-family: $nunito;
  font-size: 1rem;
  font-weight: 800;
  line-height: 2;
  color: $text;
}

@mixin btn-active($bg: white, $text: $river, $border: $bg) {
  background-color: $bg !important;
  border-color: $border;
  color: $text !important;
}

@mixin btn($bg, $text, $border: $bg, $activeBorder: $bg) {
  a,
  button {
    @include btn-link($bg, $text, $border);

    &:hover,
    &:focus,
    &:active {
      @include btn-active($text, $bg, $activeBorder);
    }
  }
}

@mixin btn--skinny {
  a,
  button {
    padding: 0.137em 1.1em 0;
    font-size: initial;
    font-weight: 700;
    line-height: 1.6;
    border-radius: 0.7em;
  }
}

@mixin btn-river {
  @include btn($river, white, $river, $stream);
}

@mixin btn-white {
  @include btn(white, $river, $stream);
}

@mixin btn-ice {
  border: 2px solid transparent;
  border-radius: 0.8em;
  overflow: hidden;

  a,
  button {
    @include btn-link($ice, $river);

    border: none;
    border-radius: 0;
  }

  &:hover,
  &:focus,
  &:active {
    border: 2px solid $stream;

    a {
      background-color: white;
    }
  }
}
